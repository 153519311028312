import { Box, Card, Container } from "@mui/material";
import { FC, ReactNode } from "react";

import gradientJumbotron from "@/assets/images/gradient-jumbotron.png";
import rectanglePattern from "@/assets/images/Rectangle12225982.png";

type JumbotronOuterProps = {
  children: ReactNode | ReactNode[];
};

export const JumbotronOuter: FC<JumbotronOuterProps> = (props) => {
  const { children } = props;

  return (
    <Container maxWidth="xl">
      <Card
        sx={{
          background: "#F8F8F8",
        }}
      >
        <Box
          sx={{
            background: `url(${rectanglePattern.src})`,
          }}
        >
          <Box
            sx={{
              background: {
                xs: "linear-gradient(0deg, rgba(19,57,255,0.2) 0%, rgba(231,83,255,0.15) 15%, rgba(255,255,255,0.2) 32%)",
                md: `url(${gradientJumbotron.src}) no-repeat`,
              },
              backgroundSize: {
                xs: "50%",
                md: "cover",
              },
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 1.5,
                  md: 3,
                  lg: 12.5,
                },
                py: {
                  xs: 1.5,
                  md: 3,
                  lg: 10,
                },
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};
