import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import { IImage } from "@/shared/types";
import { ResponsiveTypography } from "@/shared/ui";

type Props = {
  stepTitle: string;
  text: string;
  image: IImage;
};

const StepItem: FC<Props> = (props) => {
  const { stepTitle, text, image } = props;

  return (
    <Stack
      direction="row"
      spacing={2.25}
      alignItems="center"
      mb={{
        xs: 3.75,
        md: 6.375,
      }}
    >
      <Box
        display={{ xs: "none", md: "flex" }}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexShrink: 0,
          width: 105,
          height: 100,
          bgcolor: "neutral.95",
          borderRadius: 2.5,
        }}
      >
        {image?.data ? (
          <Image
            src={image.data.attributes.url}
            alt={image.data.attributes.alternativeText || ""}
            width={image.data.attributes.width}
            height={image.data.attributes.height}
          />
        ) : null}
      </Box>
      <Box>
        <Typography variant="text7" color="neutral.70" mb={0.75}>
          {stepTitle}
        </Typography>
        <ResponsiveTypography
          variantMap={{
            xs: "text6",
            md: "text5",
          }}
          fontWeight={500}
          color="neutral.0"
        >
          {text}
        </ResponsiveTypography>
      </Box>
    </Stack>
  );
};

export default StepItem;
