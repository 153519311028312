import { Box, Card, Typography } from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import bobber from "@/assets/images/bobber.svg";
import bobberWhite from "@/assets/images/bobberwhite.svg";
import { DescriptionCardItem } from "@/shared/types";

type DescriptionCardProps = Pick<
  DescriptionCardItem,
  "description" | "badge" | "comment" | "image"
> & {
  color: "white" | "primary";
};

export const DescriptionCard: FC<DescriptionCardProps> = (props) => {
  const bgColorMapByColorProp: Record<
    DescriptionCardProps["color"],
    { bgcolor: string; color: string; bobber: string }
  > = {
    white: {
      bgcolor: "neutral.100",
      color: "neutral.0",
      bobber: bobberWhite,
    },
    primary: {
      bgcolor: "primary.40",
      color: "neutral.100",
      bobber: bobber,
    },
  };

  return (
    <Box>
      <Card
        sx={{
          mb: {
            xs: 2.25,
            md: 4.125,
          },
          height: {
            xs: 372,
            md: 523,
          },
          background: `url(${props.image?.data?.attributes.url}) no-repeat top center`,
          backgroundSize: "cover",
          p: 2.5,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            background: "linear-gradient(220deg, #F0F0F0 11%, #D4D4D4 143.67%)",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
          },
        }}
      >
        {props.badge?.data?.attributes.url ? (
          <Box
            sx={{
              position: "absolute",
              top: {
                xs: 30,
                md: 37,
              },
              right: {
                xs: 30,
                md: 41,
              },
            }}
          >
            <Image
              src={props.badge?.data?.attributes.url}
              alt={
                props.badge?.data?.attributes.alternativeText || "Company logo"
              }
              width={props.badge?.data?.attributes.width}
              height={props.badge?.data?.attributes.height}
            />
          </Box>
        ) : null}

        <Box position="relative">
          <Image
            src={bgColorMapByColorProp[props.color].bobber}
            alt=""
            style={{
              position: "absolute",
              top: -23,
              left: 40,
            }}
          />
          <Card
            sx={{
              bgcolor: bgColorMapByColorProp[props.color].bgcolor,
              width: 226,
              p: 2,
              py: 1.5,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="text8"
              color={bgColorMapByColorProp[props.color].color}
            >
              {props.comment}
            </Typography>
          </Card>
        </Box>
      </Card>
      <Typography variant="headline8" color="neutral.0">
        {props.description}
      </Typography>
    </Box>
  );
};
