import { Box, Typography } from "@mui/material";

import { QuestionItem } from "@/components/home/faq/ui/question-item";
import { TFaq } from "@/shared/types";
import { SectionContainer } from "@/shared/ui";

type BlockFaqProps = Pick<TFaq, "items" | "title">;

export const Faq = (props: BlockFaqProps) => {
  return (
    <SectionContainer>
      <Box
        display={{
          xs: "block",
          md: "none",
        }}
        mb={2.5}
      >
        <Typography variant="headline5" textAlign="center">
          {props.title}
        </Typography>
      </Box>
      <Box
        display={{
          xs: "none",
          md: "block",
        }}
        mb={2.5}
      >
        <Typography variant="headline3" textAlign="center">
          {props.title}
        </Typography>
      </Box>
      {props.items?.map((item: any) => (
        <QuestionItem
          key={item.id}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </SectionContainer>
  );
};
