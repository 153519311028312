import { Container, Grid } from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import StepItem from "@/components/home/how-to-use/ui/step-item";
import { StepsList } from "@/shared/types";
import { ResponsiveTypography } from "@/shared/ui";

type Props = Pick<StepsList, "image" | "title" | "items">;

export const HowToUse: FC<Props> = (props) => {
  const { title, image, items } = props;

  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 7.5,
          md: 16.25,
          lg: 18,
        },
      }}
    >
      <ResponsiveTypography
        variantMap={{
          xs: "headline4",
          md: "headline2",
        }}
        textAlign="center"
        component="h2"
        mb={{
          xs: 3.75,
          md: 8.75,
        }}
      >
        {title}
      </ResponsiveTypography>

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} lg={7}>
          <Grid container spacing={1}>
            {items?.map((step, index) => (
              <Grid key={index} item xs={6}>
                <StepItem
                  stepTitle={step.text1}
                  text={step.text2}
                  image={step.image}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={11} lg={5}>
          {image?.data?.attributes ? (
            <Image
              src={image?.data?.attributes.url}
              width={image?.data?.attributes.width}
              height={image?.data?.attributes.height}
              alt={image?.data?.attributes.alternativeText || ""}
              style={{
                height: "auto",
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};
