import { Box, Container, Grid } from "@mui/material";
import { FC } from "react";

import { PresentationCard } from "@/components/home/presentation-slider/ui/presentation-card";
import { TPresentationCards } from "@/shared/types";
import { HorizontalSlider } from "@/shared/ui";

type PresentationSliderProps = Pick<TPresentationCards, "items">;

export const PresentationSlider: FC<PresentationSliderProps> = (props) => {
  const { items = [] } = props;

  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 16,
          md: 16.25,
          lg: 18,
        },
      }}
    >
      <Box
        display={{
          xs: "none",
          lg: "block",
        }}
      >
        <Grid container spacing={1.75}>
          {items.map((item, index) => (
            <Grid key={item.id} item xs={4}>
              <PresentationCard
                indexNumber={index + 1}
                title={item.title}
                image={item.image}
                backgroundDirection={item.backgroundDirection}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        display={{
          lg: "none",
        }}
      >
        <HorizontalSlider
          spacing={2.75}
          items={items.map((item, index) => (
            <PresentationCard
              indexNumber={index + 1}
              title={item.title}
              image={item.image}
              backgroundDirection={item.backgroundDirection}
            />
          ))}
        />
      </Box>
    </Container>
  );
};
