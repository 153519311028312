import { Box, Card, Typography } from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import { PresentationCardItem } from "@/shared/types";
import { formatNumber } from "@/utils";

type PresentationCardProps = Pick<
  PresentationCardItem,
  "image" | "backgroundDirection" | "title"
> & {
  indexNumber: number;
};

export const PresentationCard: FC<PresentationCardProps> = (props) => {
  const imageAttrs = props.image?.data?.attributes;

  const directionsMapByFlexAlignItems: Record<
    PresentationCardItem["backgroundDirection"],
    string
  > = {
    top: "flex-start",
    center: "center",
    bottom: "flex-end",
  };

  return (
    <Box>
      <Card
        sx={{
          height: {
            xs: 256,
            md: 526,
            lg: 311,
          },
          width: {
            xs: 316,
            md: 647,
            lg: "auto",
          },
          display: "flex",
          alignItems: directionsMapByFlexAlignItems[props.backgroundDirection],
          justifyContent: "center",
        }}
      >
        {imageAttrs ? (
          <Image
            src={imageAttrs.url}
            alt={imageAttrs.alternativeText || ""}
            height={imageAttrs.height}
            width={imageAttrs.width}
            style={{
              objectFit: "contain",
              height: "calc(100% - 35px)",
              width: "auto",
            }}
          />
        ) : null}
      </Card>
      <Box pt={4.375} pb={2.25}>
        <Typography variant="headline7" color="neutral.90">
          {formatNumber(props.indexNumber)}
        </Typography>
      </Box>
      {props.title ? (
        <Typography
          variant="headline7"
          sx={{
            fontSize: {
              xs: 16,
              sm: 18,
            },
            lineHeight: {
              xs: "22.4px",
              sm: "25.2px",
            },
          }}
        >
          {props.title}
        </Typography>
      ) : null}
    </Box>
  );
};
