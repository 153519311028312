import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Slider, { Settings } from "react-slick";

import { TestimonialCard } from "@/components/home/testimonials/ui/card";
import { TTestimonials } from "@/shared/types";
import { IconArrowLeft, IconArrowRight } from "@/shared/ui";

type BlockTestimonialProps = Pick<TTestimonials, "title" | "cards">;

export const Testimonials = (props: BlockTestimonialProps) => {
  const slidesOnDesktop = 3;
  const cards = props.cards || [];

  const [slider, setSlider] = useState<Slider | null>(null);

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesOnDesktop,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          infinite: true,
        },
      },
    ],
  };

  const handleNext = () => {
    slider?.slickNext();
  };

  const handlePrev = () => {
    slider?.slickPrev();
  };

  const carouselItem = (
    <Box mx={-1}>
      <Slider
        className="testimonials-slider"
        ref={(sliderRef) => setSlider(sliderRef)}
        {...settings}
      >
        {props.cards?.map(({ role, name, avatar, text, id }) => (
          <TestimonialCard
            key={id}
            role={role}
            text={text}
            name={name}
            avatar={avatar}
          />
        ))}
      </Slider>
    </Box>
  );

  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 16,
          md: 16.25,
          lg: 18,
        },
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{
          mb: {
            xs: 2.75,
            md: 6.25,
          },
        }}
      >
        <Typography
          variant="headline3"
          sx={{
            maxWidth: 280,
          }}
        >
          {props.title}
        </Typography>

        <Box
          display={{
            xs: "none",
            sm: cards.length <= slidesOnDesktop ? "none" : "block",
          }}
        >
          <IconButton
            onClick={handlePrev}
            sx={{
              color: "neutral.0",
            }}
            aria-label="Prev slide"
          >
            <IconArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              color: "neutral.0",
            }}
            aria-label="Next slide"
          >
            <IconArrowRight />
          </IconButton>
        </Box>
      </Stack>
      {carouselItem}
    </Container>
  );
};
