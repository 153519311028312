import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { FC, SyntheticEvent, useEffect, useState } from "react";

import { useShopsData } from "@/shared/hooks/use-shops-data";
import { TShopCategories } from "@/shared/types";
import { ResponsiveTypography } from "@/shared/ui";

type ShopCategoriesProps = Pick<TShopCategories, "title" | "shops">;

export const ShopCategories: FC<ShopCategoriesProps> = (props) => {
  const shopsData = useShopsData();

  const [currentCategorie, setCurrentCategorie] = useState<
    number | undefined
  >();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentCategorie(newValue);
  };

  useEffect(() => {
    setCurrentCategorie(shopsData?.shopsCategories[0]?.id);
  }, [shopsData]);

  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 7.5,
          md: 16.25,
          lg: 18,
        },
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          {props.title ? (
            <ResponsiveTypography
              variantMap={{
                xs: "headline4",
                md: "headline2",
              }}
              textAlign="center"
              component="h2"
              mb={{
                xs: 3.75,
                md: 8.75,
              }}
            >
              {props.title}
            </ResponsiveTypography>
          ) : null}
        </Grid>
      </Grid>

      <Tabs
        value={currentCategorie}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          mb: 4,
          ml: -2,
          "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
          },
        }}
      >
        {shopsData?.shopsCategories?.map((categorie: any) => (
          <Tab
            label={categorie.attributes.name}
            value={categorie.id}
            sx={{
              textTransform: "none",
              fontWeight: 700,
              color: "neutral.70",
              fontSize: 16,
              "&.Mui-selected": {
                color: "neutral.0",
              },
            }}
          />
        ))}
      </Tabs>
      <Grid container spacing={2.25}>
        {shopsData?.shopsList
          .filter(
            ({ attributes }) =>
              attributes.shop_categorie?.data?.id === currentCategorie
          )
          .map((shop) => (
            <Grid item xs={12} sm={6} md={4} key={shop.id}>
              <Box
                sx={{
                  height: 254,
                  borderRadius: 3,
                  mb: 3,
                  overflow: "hidden",
                  bgcolor: "neutral.10",
                }}
              >
                {shop.attributes.image.data?.attributes.url ? (
                  <Image
                    src={shop.attributes.image.data?.attributes.url}
                    alt={
                      shop.attributes.image.data?.attributes.alternativeText ||
                      ""
                    }
                    width={372}
                    height={254}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : null}
              </Box>
              <Typography variant="headline5">
                {shop.attributes.name}
              </Typography>
              <Typography variant="text7" color="neutral.60">
                {shop.attributes.description}
              </Typography>
            </Grid>
          ))}
      </Grid>
      <Divider
        sx={{
          mt: {
            xs: 3,
            md: 7,
            lg: 10,
          },
        }}
      />
    </Container>
  );
};
