import { Button, Card, Container, Grid, Typography } from "@mui/material";
import NextLink from "next/link";
import { FC } from "react";

import { TSimpleBanner } from "@/shared/types";
import { navigations } from "@/utils";

type SimpleBannerProps = Pick<TSimpleBanner, "link" | "title">;

export const SimpleBanner: FC<SimpleBannerProps> = (props) => {
  return (
    <Container>
      <Card
        sx={{
          pt: 15.875,
          pb: 17.875,
          px: 6.5,
          background:
            "linear-gradient(180deg, rgba(238,238,238,1) 63%, rgba(255,227,221,1) 100%)",
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={7} textAlign="center">
            <Typography
              variant="headline2"
              fontWeight={700}
              textAlign="center"
              marginBottom={{
                xs: 5.5,
                lg: 7.5,
              }}
              sx={{
                fontSize: {
                  xs: 29,
                  md: 44,
                  lg: 64,
                },
              }}
            >
              {props.title}
            </Typography>
            {props.link ? (
              <Button
                component={NextLink}
                href={navigations.signup}
                sx={{
                  width: 389,
                  maxWidth: "100%",
                }}
              >
                {props.link?.text}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
