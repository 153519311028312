import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import { TPaymentProcess } from "@/shared/types";
import { formatNumber } from "@/utils";

type PaymentProcessProps = Pick<TPaymentProcess, "title" | "items">;

export const PaymentProcess: FC<PaymentProcessProps> = (props) => {
  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 7.5,
          md: 16.25,
          lg: 18,
        },
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} md={7}>
          <Typography
            variant="headline3"
            textAlign="center"
            mb={{
              xs: 4,
              md: 6,
            }}
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="stretch">
        {props.items?.map((item, index) => (
          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                  lg: "column",
                }}
                alignItems={{
                  xs: "center",
                  md: "stretch",
                  lg: "center",
                }}
                justifyContent="space-between"
              >
                {item.image.data ? (
                  <Box
                    px={{
                      md: 2,
                    }}
                    pb={{ md: 2 }}
                  >
                    <Image
                      src={item.image.data?.attributes.url}
                      alt={item.image.data?.attributes?.alternativeText || ""}
                      width={item.image.data?.attributes.width}
                      height={item.image.data?.attributes.height}
                      style={{ height: "auto" }}
                    />
                  </Box>
                ) : null}

                <Box
                  order={{
                    xs: 0,
                    md: -1,
                    lg: 0,
                  }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={{
                    md: 250,
                    lg: "auto",
                  }}
                  p={{
                    xs: 2.5,
                    lg: 5,
                  }}
                >
                  <Typography
                    variant="headline5"
                    fontSize={28}
                    sx={{
                      position: {
                        sm: "absolute",
                        md: "static",
                      },
                      top: 50,
                      left: 40,
                      mb: {
                        xs: 3.25,
                      },
                    }}
                  >
                    {formatNumber(index + 1)}
                  </Typography>
                  <Typography variant="text6" color="neutral.30">
                    {item.text}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        mt={{
          xs: 12,
          sm: 15,
        }}
      >
        <Divider />
      </Box>
    </Container>
  );
};
