import { Avatar, Card, Typography } from "@mui/material";

import { TestimonialCard as TTestimonialCard } from "@/shared/types";

type TestimonialCardProps = Pick<
  TTestimonialCard,
  "avatar" | "name" | "role" | "text"
>;
export const TestimonialCard = (props: TestimonialCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 3.75,
        mx: 1,
        maxWidth: {
          xs: 318,
          md: 384,
          lg: "auto",
        },
        "& > *": {
          userSelect: "none",
        },
        height: "100%",
      }}
    >
      <Avatar
        alt={props.avatar?.data?.attributes.alternativeText || ""}
        src={props.avatar?.data?.attributes.url}
        sx={{ width: 100, height: 100, mb: 4.25 }}
      />

      <Typography
        variant="headline5"
        color="neutral.0"
        sx={{
          wordBreak: "break-word",
          fontSize: 23,
        }}
      >
        {props.name}
      </Typography>
      <Typography variant="text7" color="neutral.60">
        {props.role}
      </Typography>

      <Typography
        variant="body2"
        mt={2}
        color="neutral.0"
        fontWeight={600}
        sx={{
          overflow: "auto",
        }}
      >
        {props.text}
      </Typography>
    </Card>
  );
};
