import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";

import { ExpandIconFAQ, Parser } from "@/shared/ui";

interface QuestionItemProps {
  question: string;
  answer: string;
}

export const QuestionItem = (props: QuestionItemProps) => {
  const { answer, question } = props;

  const theme = useTheme();

  const expandIconSVGContainerStyles = {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .25s ease",
  };

  return (
    <Accordion
      sx={{
        "&.MuiAccordion-root": {
          border: "none",
          borderBottomWidth: 1,
          borderBottomColor: "neutral.90",
          borderBottomStyle: "solid",
          "&:before": {
            display: "none",
          },
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "none",
            "& .expand_icon_line_transformed": {
              transform: "rotate(0deg)",
            },
          },
        }}
        expandIcon={
          <Box
            className="expand_icon_container"
            sx={{
              width: 35,
              height: 35,
              borderRadius: 100,
              position: "relative",
              transition: "all .25s ease",
              [theme.breakpoints.down("md")]: {
                transform: "scale(0.75)",
              },
            }}
          >
            <Box sx={expandIconSVGContainerStyles}>
              <ExpandIconFAQ />
            </Box>
            <Box
              className="expand_icon_line_transformed"
              sx={{
                ...expandIconSVGContainerStyles,
                transform: "rotate(90deg)",
              }}
            >
              <ExpandIconFAQ />
            </Box>
          </Box>
        }
      >
        <Box
          display={{
            xs: "block",
            md: "none",
          }}
        >
          <Typography variant="headline8">{question}</Typography>
        </Box>
        <Box
          display={{
            xs: "none",
            md: "block",
          }}
        >
          <Typography variant="headline6">{question}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          color: "neutral.50",
          px: 2,
        }}
      >
        {answer ? <Parser data={answer} /> : null}
      </AccordionDetails>
    </Accordion>
  );
};
