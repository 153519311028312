import { Container, Grid } from "@mui/material";
import { FC } from "react";

import { DescriptionCard } from "@/components/home/description-cards/ui/card";
import { TDescriptionCards } from "@/shared/types";
import { ResponsiveTypography } from "@/shared/ui";

type DescriptionCardsProps = Pick<TDescriptionCards, "cards" | "title">;

export const DescriptionCards: FC<DescriptionCardsProps> = (props) => {
  return (
    <Container
      sx={{
        mt: {
          xs: 8.125,
          md: 2.825,
          lg: 13.875,
        },
        mb: {
          xs: 16,
          md: 16.25,
          lg: 18,
        },
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <ResponsiveTypography
            variantMap={{
              xs: "headline4",
              md: "headline2",
            }}
            textAlign="center"
            component="h2"
            mb={{
              xs: 3.75,
              md: 8.75,
            }}
          >
            {props.title}
          </ResponsiveTypography>
        </Grid>
      </Grid>
      <Grid container spacing={2.25}>
        {props.cards?.map((card, index) => (
          <Grid item xs={12} md={6} key={card.id}>
            <DescriptionCard
              description={card.description}
              comment={card.comment}
              image={card.image}
              badge={card.badge}
              color={index % 2 === 0 ? "primary" : "white"}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
