import { createContext, useContext } from "react";

import { ShopCategorie, ShopItem } from "@/shared/api/shops-api";

export const ShopsContext = createContext<null | {
  shopsList: ShopItem[];
  shopsCategories: ShopCategorie[];
}>(null);

export const useShopsData = () => {
  const context = useContext(ShopsContext);

  if (context === undefined) {
    throw new Error("useShopsData must be used within a Context Provider");
  }

  return context;
};
