import { useCommonApi } from "@/shared/contexts/common-api";
import { Header } from "@/shared/ui";

export const MainLayoutHeader = () => {
  const commonApiContext = useCommonApi();

  const headerNavigationItems =
    commonApiContext?.layoutSettings?.attributes?.headerNavigation || [];

  return <Header navLinks={headerNavigationItems} />;
};
