import { Box, Stack } from "@mui/material";
import { PropsWithChildren } from "react";

import { FooterData } from "@/shared/api/common-api";

import { MainLayoutFooter } from "./main-layout-footer";
import { MainLayoutHeader } from "./main-layout-header";

export type MainLayoutProps = PropsWithChildren<{ footer?: FooterData }>;

export const MainLayout = ({ footer, children }: MainLayoutProps) => {
  return (
    <>
      <Stack
        sx={{
          height: 1,
          margin: "auto",
        }}
      >
        <MainLayoutHeader />
        <Box component="main" pt={{ sm: 2 }} sx={{ flex: "1 0 auto" }}>
          {children}
        </Box>

        <MainLayoutFooter footer={footer} />
      </Stack>
    </>
  );
};
