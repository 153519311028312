import { FooterData } from "@/shared/api/common-api";
import { useCommonApi } from "@/shared/contexts/common-api";
import { getLinksFromNavData, NavLink } from "@/shared/lib";
import { Footer } from "@/shared/ui";

export type MainLayoutFooterProps = { footer?: FooterData };

export const MainLayoutFooter = ({ footer }: MainLayoutFooterProps) => {
  const commonApiContext = useCommonApi();

  const footerContent =
    footer ?? commonApiContext?.layoutSettings?.attributes?.footer;

  const footerNavigationItems =
    commonApiContext?.layoutSettings?.attributes?.footerNavigation || [];

  const links: NavLink[] = getLinksFromNavData(footerNavigationItems);

  return footerContent ? (
    <Footer content={footerContent} links={links} />
  ) : null;
};
