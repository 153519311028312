export { PageMap } from "./page-map";
export { JumbotronSection } from "./jumbotron";
export { PresentationSlider } from "./presentation-slider";
export { DescriptionCards } from "./description-cards";
export { ShopCategories } from "./shop-categories";
export { Testimonials } from "./testimonials";
export { Faq } from "./faq";
export { DownloadAppBanner } from "./download-app-banner";
export { MerchantJumbotron } from "./merchant-jumbotron";
export { StepperBlock } from "./stepper";
export { SimpleBanner } from "./simple-banner";
export { PaymentProcess } from "./payment-process";
export { HowToUse } from "./how-to-use";
export * from "./partners-stores-section";
