import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import Image from "next/image";
import NextLink from "next/link";
import { FC } from "react";

import { JumbotronOuter } from "@/components/common";
import { TMerchantJumbotron } from "@/shared/types";
import { navigations } from "@/utils";

type MerchantJumbotronProps = Pick<
  TMerchantJumbotron,
  | "bottomTextMobile"
  | "buttonText"
  | "image"
  | "description"
  | "titleDesktop"
  | "signupButtonText"
>;

export const MerchantJumbotron: FC<MerchantJumbotronProps> = (props) => {
  const imageAttributes = props.image?.data?.attributes;

  return (
    <JumbotronOuter>
      <Grid
        container
        spacing={2}
        justifyContent={{
          xs: "center",
          lg: "space-between",
        }}
        textAlign={{
          xs: "center",
          lg: "left",
        }}
      >
        <Grid item xs={12} md={7} lg={5}>
          <Stack
            justifyContent="space-between"
            height="100%"
            p={{
              xs: 2.5,
              lg: 0,
            }}
          >
            <Box>
              <Typography
                variant="headline2"
                component="h2"
                mb={{
                  xs: 2.5,
                  md: 5,
                  lg: 2.25,
                }}
                sx={{
                  lineHeight: "110%",
                  fontWeight: 700,
                  fontSize: {
                    xs: 38,
                    md: 55,
                    lg: 66,
                  },
                }}
              >
                {props.titleDesktop}
              </Typography>

              <Typography
                variant="body2"
                color="neutral.40"
                mb={{
                  xs: 6.875,
                  md: 4.875,
                }}
              >
                {props.description}
              </Typography>
              <Typography
                variant="headline4"
                marginBottom={2.5}
                fontSize={18}
                color="neutral.0"
                display={{
                  lg: "none",
                }}
              >
                {props.bottomTextMobile}
              </Typography>
            </Box>

            <Box>
              <Button
                sx={{
                  backgroundColor: "neutral.0",
                  borderRadius: 10,
                }}
                component={NextLink}
                href={navigations.signup}
              >
                {props.signupButtonText}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Card
            sx={{
              textAlign: "center",
              bgcolor: "neutral.100",
              maxWidth: 525,
              p: {
                xs: 3.5,
                md: 5,
                lg: 5.25,
              },
            }}
          >
            {imageAttributes ? (
              <Box
                display="flex"
                justifyContent="center"
                mb={{
                  xs: props.buttonText ? 4.375 : 0,
                  md: 0,
                }}
              >
                <Image
                  src={imageAttributes.url}
                  alt={imageAttributes.alternativeText || ""}
                  width={imageAttributes.width}
                  height={imageAttributes.height}
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    maxHeight: 490,
                  }}
                />
              </Box>
            ) : null}

            {props.buttonText ? (
              <Button
                component={NextLink}
                href={navigations.signup}
                sx={{
                  display: { xs: "inline-block", md: "none" },
                  width: { xs: "100%", sm: 272 },
                }}
              >
                {props.buttonText}
              </Button>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </JumbotronOuter>
  );
};
