import { Card, Container, Grid, Typography } from "@mui/material";

import { TStepperBnpl } from "@/shared/types";

type StepperBlockProps = Pick<TStepperBnpl, "stepperItem">;

export const StepperBlock: React.FC<StepperBlockProps> = (props) => {
  return (
    <Container>
      <Card
        sx={{
          background: "#F8F8F8",
          p: 8.75,
        }}
      >
        <Grid container spacing={5}>
          {props.stepperItem?.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="headline5" mb={2.5}>
                {item.text1}
              </Typography>
              <Typography variant="text6" color="neutral.60">
                {item.text2}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  );
};
