import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import Image from "next/image";

import { PartnersStoresSectionType } from "@/shared/types";
import { ResponsiveTypography } from "@/shared/ui";

type PartnersStoresSectionProps = PartnersStoresSectionType;

export const PartnersStoresSection = ({
  title,
  stores,
}: PartnersStoresSectionProps) => {
  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        mb={{
          xs: 6.25,
          md: 10.5,
        }}
      >
        <ResponsiveTypography
          variantMap={{
            xs: "headline4",
            md: "headline2",
          }}
          component="h2"
          textAlign="center"
          maxWidth={{ xs: 300, md: 464 }}
        >
          {title}
        </ResponsiveTypography>
      </Box>

      <Grid
        container
        columnSpacing={{ sm: 2.5, md: 1.75 }}
        rowSpacing={{ xs: 6, sm: 10, md: 6.25 }}
      >
        {stores.map(({ title: storeTitle, description, image }, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ borderRadius: 0, backgroundColor: "unset" }}>
              <Stack gap={{ xs: 3, md: 3.75 }}>
                <Image
                  src={image.data?.attributes?.url ?? ""}
                  alt={image.data?.attributes?.alternativeText ?? ""}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 20,
                    objectFit: "cover",
                  }}
                />

                <Box>
                  <Typography variant="headline5">{storeTitle}</Typography>
                  {description ? (
                    <Typography variant="text7" color="neutral.80">
                      {description}
                    </Typography>
                  ) : null}
                </Box>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
